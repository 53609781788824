import React, { act, useEffect } from "react";
import Thread from "../thread/thread";
import { EventEmitter } from "../../../../shared/utils/event-emitter";
import Events from "../../../../const/events";
import ThreadHelper from "../../../../entities/thread/thread-helper";
import { useSelector } from "react-redux";
import ThreadViewTabs from "../../../../const/thread-view-tabs";
import { DebounceInput } from "react-debounce-input";
import useDeepCompareEffect from "use-deep-compare-effect";
import TemplateHelper from "../../../../entities/templates/template-helper";
import "./threads-list-viewer.scss";

const ThreadsListViewer = () => {
  const [threadListActiveView, setThreadListActiveView] = React.useState(
    ThreadViewTabs.Individual
  );
  const [unreadCounts, setUnreadCounts] = React.useState({
    Rooms: 0,
    Individual: 0,
  });
  const _setSelectedTab = (tab) => {
    setThreadListActiveView(tab);
  };
  const allThreads = useSelector(
    (state) => state?.threadStore?.allThreads || []
  );
  const isLoaderActive = useSelector(
    (state) => state?.threadStore?.isLoaderActive || false
  );
  const selectedThread = useSelector(
    (state) => state?.threadStore?.selectedThread || null
  );
  const searchText = useSelector(
    (state) => state?.threadStore?.searchText || ""
  );

  const _updateSearchText = (e) => {
    ThreadHelper.setSearchText(e.target.value);
  };
  const _showCreateThreadPopup = () => {
    EventEmitter.dispatch(Events.SHOW_CREATE_THREAD_POPUP);
  };
  useEffect(() => {
    const _loadThreads = () => {
      ThreadHelper.loadThreadsList();
    };
    const _loadTwilioTemplates = () => {
      TemplateHelper.loadTwilioTemplates();
    }
    _loadTwilioTemplates();
    _loadThreads();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isLoaderActive) {
        ThreadHelper.checkForThreadUpdates();
      }
    }, 5000); // Poll every 5 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [isLoaderActive]);

  useDeepCompareEffect(() => {
    const unreadCounts = {
      Rooms: 0,
      Individual: 0,
    };

    allThreads.forEach((thread) => {
      if (thread.type === "room") {
        unreadCounts.Rooms += thread.unreadMessages || 0;
      } else {
        unreadCounts.Individual += thread.unreadMessages || 0;
      }
    });

    setUnreadCounts(unreadCounts);
  }, [allThreads]);

  return (
    <>
      <ul className="nav nav-tabs mb-3 nav-justified" id="myTab" role="tablist">
        {Object.values(ThreadViewTabs).map((tab) => (
          <li className="nav-item" role="presentation" key={tab}>
            <a
              className={`nav-link ${
                threadListActiveView === tab ? "active" : ""
              }`}
              data-bs-toggle="tab"
              href={`#`}
              onClick={(e) => _setSelectedTab(tab)}
            >
              {tab}

              {unreadCounts[tab] > 0 ? <span>{unreadCounts[tab]}</span> : ""}
            </a>
          </li>
        ))}
      </ul>

      <div className="chat-search mb-3">
        {threadListActiveView === ThreadViewTabs.Individual && (
          <div className="chat-new-start mb-3">
            <button
              type="button"
              class="btn btn-success w-100"
              onClick={_showCreateThreadPopup}
            >
              <i class="mdi mdi-plus me-2"></i>
              New message
            </button>
          </div>
        )}

        <div className="form-group">
          <div className="input-group">
            <DebounceInput
              minLength={2}
              debounceTimeout={300}
              onChange={_updateSearchText}
              id="chat-search"
              name="chat-search"
              className="form-control"
              placeholder="Search"
              value={searchText}
            />
            {/* <button type="button" className="btn btn-de-primary shadow-none">
              <i className="la la-search" />
            </button> */}
          </div>
        </div>
      </div>
      {/*end chat-search*/}

      <div className="chat-body-left" >
        <div className="tab-content chat-list" id="pills-tabContent">
          <div className="tab-pane fade show active" id="all_chat">
            {isLoaderActive && (
              <div className="thread-loader-wrapper">
                <img
                  src="assets/images/ring-loader.svg"
                  alt="loader"
                  className="loader"
                />
              </div>
            )}
            {allThreads.length === 0 && !isLoaderActive && (
              <div className="no-threads">
                <h4>No threads found</h4>
                <p>Start a new conversation by clicking the button above</p>
              </div>
            )}

            {/* Loop through allThreads and render a Thread component for each */}
            {allThreads.map((thread) => {
              let contactName = "Unknown Contact";

              if (thread.title) {
                contactName = thread.title;
              } else if (thread._contacts?.length) {
                contactName = thread._contacts[0].name
                  ? thread._contacts[0].name
                  : thread._contacts[0].name;
              }
              const lastMessage =
                thread.lastMessagePreview || "No messages yet";
              const unreadCount = thread.unreadMessages || 0;

              if (threadListActiveView === ThreadViewTabs.Rooms) {
                if (thread.type !== "room") {
                  return null;
                }
                contactName = thread.title.replace("and", " + ");
              }
              if (threadListActiveView === ThreadViewTabs.Individual) {
                if (thread.type === "room") {
                  return null;
                }
              }

              return (
                <Thread
                  key={thread._id}
                  threadId={thread._id}
                  userImg={
                    thread.type === "room" ? "/assets/images/group_duo.png" : ""
                  } // Assuming no user image for now
                  userInitials={contactName.charAt(0)}
                  userName={contactName}
                  message={lastMessage}
                  date={thread.updatedAt}
                  unreadCount={unreadCount}
                  isActive={selectedThread?._id === thread._id}
                  thread={thread}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ThreadsListViewer;
