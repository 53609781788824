import React from "react";
import ThreadHelper from "../../../../entities/thread/thread-helper";

const Thread = ({
  userImg,
  userInitials,
  userName,
  message,
  date,
  unreadCount,
  threadId,
  isActive,
  thread,
}) => {
  return (
    <a
      href="#"
      className={`media ${isActive ? "new-message" : ""}`}
      onClick={(e) => {
        e.preventDefault();
        ThreadHelper.makeThreadActive(thread);
      }}
    >
      <div className="media-left">
        {/* If user image exists, show it, otherwise show initials */}
        {userImg ? (
          <img src={userImg} alt="user" className="rounded-circle thumb-md" />
        ) : (
          <div className="avatar-box thumb-md align-self-center">
            <span className="thumb-md justify-content-center d-flex align-items-center bg-soft-primary rounded-circle">
              {userInitials}
            </span>
          </div>
        )}
        {thread?.type === "room" &&
          thread.lastMessagePreview.indexOf("left the room") === -1 && (
            <span className="round-10 bg-success" />
          )}
      </div>
      <div className="media-body">
        <div className="d-inline-block">
          <h6>{userName}</h6>
          <p>{message}</p>
        </div>
        <div>
          <span className="time">{ThreadHelper.getFormattedDateTime(date)}</span>
          {unreadCount > 0 ? <span>{unreadCount}</span> : ""}
        </div>
      </div>
    </a>
  );
};

export default Thread;
